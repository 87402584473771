// components
import BlogArticle from '../components/BlogArticle';

// elements
import LazyImage from '../elements/LazyImage';

/**
 * @function TheRawFoodPyramid
 * @description Functional component containing blog markup
 * @returns {JSX} Markup for blog article
 */
const TheRawFoodPyramid = () => {
    return (
        <BlogArticle>
            <>
                <h1>The Raw Food Pyramid</h1>
                <p className="date">January 23, 2019</p>
                <p>Let's talk about raw food. Yeah, it's all the rage right now, as it should be. But honestly, can we say that we know why?</p>
                <p>Sure, most of us know why but for those of us who don't, let's go ahead and discuss those points right now. Eating raw foods keeps the nutrients in the food which means more nutrients get to you when you consume them. Don't get me wrong, I love steaming broccoli until it's a big pile of mush and then coating it in a good amount of butter with a pinch of salt, garlic, and pepper. Who doesn't?</p>
                <p>But it's poor dietary habits like that, that are going to catch up with us eventually unless we do something about it. Starting now. Getting ourselves educated is the first step. And everyone knows, steps are good.</p>
                <LazyImage
                    imgSrc='/images/raw-food-pyramid.png'
                    textName='The Raw food pyramid and its 8 categories'
                    showLoader={true}
                    className=""
                />
                <p>Now, taking a look at the diagram pictured above, we can see at the bottom we have water. It's a widely known fact that our bodies are made up of almost 60% water. This is undebatable. So, if you are not consuming the proper amount of water then, shame on you! Just kidding, no hard feelings. But you should know that scientists have confirmed that we should be drinking approximately 1/2 our body weight in ounces. To make that more understandable, let's use easy math. For example, if you weigh 50 pounds unless you are a child, I truly hope you weigh more. Anyway, for 50 pounds, you should drink approximately 25 ounces of water a day. See? That was pretty easy.</p>
                <p>And then you ask, water? What has water done for me lately? Well, if you are not an avid water drinker, perhaps you are getting water from the foods you eat but my dear yogi, I would recommend you ingest a bit more. Water creates saliva in your mouth. Got a dry mouth, try drinking some water. Water also helps regulate your body temperature. Are you sweating excessively? Perhaps you are dehydrated. Water flushes out your body waste, lubricates your joints, and much more. Don't downplay the role of water in your life.</p>
                <p>Next up is leafy greens. Not only do they taste good but they are ridiculously good for you. You should eat them generously and often. These foods are notorious for having high amounts of vitamins and minerals. They also are jam-packed full of fiber which helps keep you regular. The health benefits of leafy greens can include lowering the risk of obesity, high blood pressure as well as heart disease. Start adding more leafy greens to your diet today.</p>
                <p>Fruits are everyone's favorite. Unless you are a person who happens to dislike fruit. In which case, that's unfortunate. Anyway, fruit is on the same level as leafy greens and should be eaten generously. They are packed with vitamins and minerals and they help reduce the risk of numerous diseases. Fruits make up every color of the rainbow. Having an assortment of fruit daily is bound to improve your mood and your health.</p>
                <p>The next section that should be eaten moderately is sprouts, legumes, seeds & nuts. What exactly are legumes, you ask? Legumes are types of seeds or pods. Examples include beans, chickpeas, peas, alfalfa, clover, lentils, etc. Sprouts that can be consumed include broccoli, wheat, and radish sprouts. Put them into a salad and enjoy.</p>
                <p>Algae, herbs, and seaweed, these types of foods should be eaten very sparingly. Adding these types of foods to your diet is said to prolong longevity and it's also great for flavor!</p>
                <p>Remember, eating well is treating your body well. The more you treat your body well, the better it will be able to serve you. Combine healthy eating with an active lifestyle and you will be all set!</p>
            </>
        </BlogArticle>
    );
};

export default TheRawFoodPyramid;