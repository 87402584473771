// elements
import Link from '../elements/Link';

/**
 * @function BlogHeader
 * @description Functional component containing blog return link
 * @returns {JSX} Markup for blog header link
 */
const BlogHeader = () => {
    return (
        <p className="return-link">
            <Link href="/blog">&lt; Return to all Blogs</Link>
        </p>
    );
};

export default BlogHeader;