// components
import BlogArticle from '../components/BlogArticle';

// elements
import LazyImage from '../elements/LazyImage';

/**
 * @function SevenReasonsToStartADailyPractice
 * @description Functional component containing blog markup
 * @returns {JSX} Markup for blog article
 */
const SevenReasonsToStartADailyPractice = () => {
    return (
        <BlogArticle>
            <>
                <h1>Seven Reasons to Begin a Daily Yoga Practice</h1>
                <p className="date">November 11, 2019</p>
                <LazyImage
                    imgSrc='/images/jenny-meditative.jpg'
                    textName='Jenny in meditative practice'
                    showLoader={true}
                    className=""
                />
                <p>Everyone knows how important a fitness routine can be as well as some of the health and lifestyle benefits it can bring to your life. Yoga is another way to provide your mind and body with an intentional practice of self-care and love. Even if your daily practice is limited to 15 minutes a day, you can achieve amazing results.</p>
                <p>Here are 7 ways maintaining a daily yoga practice can be beneficial to you.</p>

                <h2>Improved Concentration</h2>
                <p>When we practice yoga, a good class will help us leave our troubles, our worries, and our cares at the door. Practicing provides a sacred space for just you and your mat. The focused physical balances, intentional breathing, and stillness help improve concentration not only in your practice but these benefits can be experienced off the mat and in your daily life as well.</p>

                <h2>Mindfulness and Gratitude</h2>
                <p>Daily yoga practices along with intentional daily meditation can result in beautiful awakenings of mindfulness and gratitude. When our minds are clear we are more capable to deal with the everyday life situations that arise. Accepting our lives and the people we have in them for what they are can give us a better understanding and the tools and preparedness we need for positive interactions. Mindful responses to situations can be the benefit of daily meditation. These acts of complete mindfulness give us an allowance to be more grateful for the things we have and the people in our lives.</p>

                <h2>Easy breathing and Reduced Anxiety</h2>
                <p>Ever notice how people who practice yoga appear to be a little less stressed and anxiety consumed than some other people? It's not because we've found the secret to not letting things bother us. On the contrary, things bother us just as much as anyone else. We've just learned that as much as things can bother us, it's critically important to take time for yourself.</p>
                <p>
                    A daily yoga practice can give you as much as you put in. When you surrender yourself and your thoughts about everything other than your practice, even for just 15 minutes, you are doing your mind and body a great service. You are not allowing the demands of life to consume you and you give your mind, body, and spirit a chance to recover from the daily grind and normal stresses.
                </p>

                <h2>Increased Energy</h2>
                <p>Practicing yoga daily helps improve your well-being and gives you an energy boost. The same as you would feel after going to the gym and having a heavy workout. Yoga can be equally intense and provide the same energy-boosting effects. Maintaining the practice daily boosts energy and you will find yourself missing your routine when a day is missed, as the shift will be noticeable.</p>

                <h2>Weight Loss</h2>
                <p>Yoga is a great way to lengthen your muscles and make your body lean and trim. It can also be a vigorous workout when attending a fast-paced or hot yoga practice. Consistent practicing can help with weight loss as well as provide a mentality to eat better and practice healthy food habits. Yoga is about the unity of mind and body. For every shift your body experiences, your mind experiences a shift in turn.</p>

                <h2>Strength, Improved Flexibility</h2>
                <p>Using your daily practice to journey deeper into your flexibility and experience the full depths of your physical range are among the visual benefits of yoga. Many people are excited about the improved strength and flexibility yoga can provide, as these benefits help with so many health issues. Increased flexibility can lead to pain relief in different areas of the body.</p>

                <h2>Self Acceptance</h2>
                <p>It is always important to remember that yoga is a means of creating unity. Uniting the body with the mind and the spirit. Although we can all be guilty of watching others and thinking, "I'm not as flexible as her..." "He has such great strength, I wonder if I will ever be that strong..."</p>
                <p>You must remember, everyone starts somewhere. When you are maintaining your daily practice, you have no choice but to get in touch with your physical limits and weaknesses. Daily practice can help you slowly push past your previous limits, and with control, you will find yourself practicing asanas and maintaining balances longer and deeper than you ever thought possible. Everyone is on their own journey. No one can take your journey for you. Self-acceptance is the only way through.</p>

            </>
        </BlogArticle>
    );
};

export default SevenReasonsToStartADailyPractice;