// libraries
import { useEffect } from 'react';

// elements
import Link from '../elements/Link';

/**
 * @function Calendar
 * @description Functional component containing calendar markup
 * @returns {JSX} Markup for calendar component
 */
const Calendar = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <h1>Class Calendar</h1>
            <div className="calendar-wrapper">
                <div className="calendar-small">
                    <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FNew_York&showDate=1&showNav=1&showTitle=0&showPrint=0&showCalendars=0&showTz=0&showTabs=0&mode=AGENDA&title=Love%20One%20Yogi%20Studio%20Calendar&src=MWY0OTYxZjY2Y2M0NWY2ZGViMTNhMzVhMzJiMTdmOTRkNWE5ODE5ZDU2ZGZiNDllZTg3MDIxMGU2MzkyODQ1ZUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%234285F4"
                        width="400"
                        height="400"
                        style={{ border: 0 }}
                        loading="lazy"
                        title="Map of Love One Yogi Studio"
                        referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>

                <div className="calendar-large">
                    <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FNew_York&showTz=0&showCalendars=0&showPrint=0&showTitle=0&src=MWY0OTYxZjY2Y2M0NWY2ZGViMTNhMzVhMzJiMTdmOTRkNWE5ODE5ZDU2ZGZiNDllZTg3MDIxMGU2MzkyODQ1ZUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5"
                        width="400"
                        height="400"
                        style={{ border: 0 }}
                        loading="lazy"
                        title="Map of Love One Yogi Studio"
                        referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
                <div className="ctas">
                    <p>Class schedule and times are updated frequently to maintain accuracy. View all <Link href='/classes'>classes and prices</Link>, and our <Link href='/studio/location'>studio's location</Link>.</p>
                </div>
            </div>
        </>
    );
};

export default Calendar;
