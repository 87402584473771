// library
import { useEffect, useState } from 'react';

// elements
import Link from "../elements/Link";

/**
 * @function Navigation
 * @description Functional component containing navigation markup
 * @returns {JSX} Markup for navigation component
 */
const Navigation = () => {
    const [menuIsActive, setMenuActive] = useState(false);
    const onMenuHandler = () => {
        setMenuActive(!menuIsActive);
    };

    const closeMenu = () => {
        setMenuActive(false);
    };

    const currentPath = window.location.pathname.replace('/', '');

    const links = ['about', 'classes', 'calendar', 'studio', 'blog'];

    useEffect(() => {
        function updateScreenHandler() {
            closeMenu();
        }

        window.addEventListener('resize', updateScreenHandler);
        return () => {
            window.removeEventListener('resize', updateScreenHandler);
        };
    }, []);

    return (
        <nav id="main-nav" className="main-nav">
            <div className="main-nav__wrapper">
                <Link href='/' className='logo' cleanupFunc={closeMenu}>
                    <img src="/images/lotus.svg" alt="Love One Yogi logo" />
                    <span className="specialty">Love One Yogi</span>
                </Link>
                <>
                    <span className="material-symbols-outlined" onClick={onMenuHandler}>
                        {menuIsActive ? 'close' : 'menu'}
                    </span>
                    <ul className={`nav-links ${menuIsActive ? 'active' : ''}`}>
                        {links.map((link, index) => (
                            <li key={index}>
                                <Link
                                    href={`/${link}`}
                                    cleanupFunc={onMenuHandler}
                                    className={currentPath.startsWith(link) ? 'active' : ''}>
                                    {currentPath.startsWith(link) ? <span className="material-symbols-outlined">keyboard_double_arrow_left</span> : ''}
                                    {link}
                                    {currentPath.startsWith(link) ? <span className="material-symbols-outlined">keyboard_double_arrow_right</span> : ''}
                                </Link>
                            </li>
                        ))}
                        {/* <li>
                            <a href="https://www.redbubble.com/people/loveoneyogi/works/142151589-love-one-yogi-original-logo?asc=u" 
                            target="_blank" 
                            rel="noreferrer">Online Shop</a>
                        </li> */}
                    </ul>
                    <div
                        onClick={closeMenu}
                        className={`overlay-bg ${menuIsActive ? 'active' : ''}`}>
                    </div>
                </>
            </div>
        </nav>
    );
};

export default Navigation;
