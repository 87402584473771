// libraries
import { useEffect } from 'react';

// elements
import Link from '../elements/Link';
import LazyBackground from '../elements/LazyBackground';
import LoadingSpinner from '../elements/LoadingSpinner';

/**
 * @function Home
 * @description Functional component containing home page markup
 * @returns {JSX} Markup for home page component
 */
const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const imageSrc = LazyBackground('/images/jenny-stretch.jpg');

    return (
        <>
            {imageSrc ?
                <div className='home-page-hero' style={{ backgroundImage: `url(${imageSrc})`, opacity: `${imageSrc ? 1 : 0.5}` }}>
                    <div className='content-wrapper'>
                        <p>The <strong>Love One Yogi Studio</strong> offers a variety of classes ranging from Hot Yoga, Vinyasa Yoga, Zumba, and Meditation. Here at Love One Yogi Studio we offer affordable classes by maintaining a space that is simple and welcoming. Our studio space is inviting and intimate.</p>
                        <Link href="/classes" className='button-primary'>Our Classes</Link>
                    </div>
                </div> :
                <div className='full-page'><div className="center-spinner"><LoadingSpinner /></div></div>
            }
        </>
    );
};

export default Home;
