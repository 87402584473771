// libraries
import { Suspense } from 'react';

// components
import Route from './components/Route';
import Navigation from './components/Navigation';
import Footer from './components/Footer';

// elements
import LoadingSpinner from './elements/LoadingSpinner';

// constants
import routes from './constants/routes';

// styles
import './App.css';


function App() {
  return (
    <div className="App">
      <Suspense fallback={<div className='full-page'><div className="center-spinner"><LoadingSpinner /></div></div>}>
        <Navigation />

        {routes.map((route, index) => {
          let mainClass;

          if (route.path === '/' || route.path === '/blog') {
            mainClass = 'full';
          } else if (route.path.startsWith('/blog/')) {
            mainClass = 'blog-article';
          } else if (route.path.startsWith('/studio')) {
            mainClass = 'studio';
          } else {
            mainClass = route.path.replace('/', '');
          }

          return (
            <Route path={route.path} key={index}>
              <div className='main-wrapper'>
                <main className={mainClass}>
                  {route.component}
                </main>
              </div>
            </Route>
          )
        })}

        <Footer />
      </Suspense>
    </div>
  );
}

export default App;
