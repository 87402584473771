// libraries
import React from 'react';

/**
 * @function Link
 * @description Functional component link markup
 * @returns {JSX} Markup for link component
 */
const Link = ({ className, href, cleanupFunc = () => {}, children }) => {
  // prevent full page reload
  const onClick = (event) => {
    if (event.metaKey || event.ctrlKey) {
      return;
    }
      
    event.preventDefault();
    window.history.pushState({}, "", href);

    // communicate to Routes that URL has changed
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
    cleanupFunc();
  };

  return (
    <a className={className} href={href} onClick={onClick}>
      {children}
    </a>
  );
};

export default Link;