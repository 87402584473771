/**
 * @function BlogSignature
 * @description Functional component containing blog signature
 * @returns {JSX} Markup for blog signature
 */
const BlogSignature = () => {
    return (
        <>
            <p>My heart to yours.</p>
            <p className="specialty">Love &mdash; One Yogi</p>
        </>
    );
};

export default BlogSignature;