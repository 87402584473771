// utilities
import { capitalize } from '../utils/ultility';

// constants
import * as Posts from '../blog-posts/react';
import blogs from './blogs';


const componentNames = 
    blogs
    .map(blog => blog.split('-'))
    .map(group => group.map(item => capitalize(item)).join(''));


const blogPaths = [];
blogs.forEach((blog, index) => {
    const Component = Posts[componentNames[index]];
    blogPaths.push({
        path: `/blog/${blogs[index]}`,
        component: <Component />
    });
});

export default blogPaths; 
