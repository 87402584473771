// components
import BlogArticle from '../components/BlogArticle';

// elements
import LazyImage from '../elements/LazyImage';

/**
 * @function FiveWaysToGetGrounded
 * @description Functional component containing blog markup
 * @returns {JSX} Markup for blog article
 */
const FiveWaysToGetGrounded = () => {
    return (
        <BlogArticle>
            <>
                <h1>5 Ways to Get Grounded</h1>
                <p className="date">February 18, 2019</p>
                <LazyImage
                    imgSrc='/images/jenny-grounded.jpg'
                    textName='Jenny'
                    showLoader={true}
                    className=""
                />
                <p>What does it mean to get grounded?</p>
                <p>Being grounded is feeling connected with the earth and your being. When we are grounded, we are conscious, caring, and confident. Feeling confident in your body means also having a focused mind. If you are grounded, your root chakra is healthy and balanced, and you can feel more peaceful in your life.</p>
                <p>When we are not grounded we can suffer from the following issues:</p>
                <ul>
                    <li>inability to focus on projects</li>
                    <li>feeling nervous or anxious (without reason)</li>
                    <li>struggling with money</li>
                    <li>unable to control food quantities, eating unhealthily</li>
                </ul>
                <p>There are lots of ways we can focus on grounding ourselves. It just takes a little bit of effort.</p>

                <h2>Yoga</h2>
                <p>Yoga &mdash; practicing yoga is a surefire way to get yourself grounded quickly. When we practice yoga, either at home or in a studio, we are making time for ourselves. Giving our bodies and our minds a chance to connect and release any tension or responsibilities that weigh on us day after day. Standing and seated asanas help with grounding. Feeling the earth below you helps to promote awareness in your body and the support the earth provides.</p>

                <h2>Meditation</h2>
                <p>Meditation &mdash; daily meditation or meditating when you can, can also help ground you. Meditation is not as intimidating as it seems. The purpose of meditation is not to "quiet" the mind so that the mind is completely silent. The intent is to be aware of the thoughts that flow through your mind, without allowing yourself to become distracted by them. Listening to a grounded guided meditation can also assist.</p>

                <h2>Get out in nature</h2>
                <p>Get out in nature &mdash; nothing is better at reminding us of our earthly existence than getting out into nature every once in a while. Take a stroll through the park, sit amongst the grass, and touch a few flowers. Do some bird watching. Nature and humans go hand in hand. Allow the beauty of nature to bring you back to earth.</p>

                <h2>Eat</h2>
                <p>Eat &mdash; make yourself a meal with a variety of root vegetables, vegetables that grow underground. This will provide your body with more nutrients from the earth and help you feel more connected to nature in the process.</p>

                <h2>Water</h2>
                <p>Water &mdash; sit near a stream, beach, or flowing body of water. The sound of the water will help calm you and bring your attention back to the center. If fresh water is unavailable, listen to an audio stream of water flowing. Allow the sounds of the water to fill your being and connect you.</p>

            </>
        </BlogArticle>
    );
};

export default FiveWaysToGetGrounded;
