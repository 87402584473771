// libraries
import { useEffect } from 'react';

// utilities
import { capitalize } from '../utils/ultility';

// elements
import Link from '../elements/Link';
import LazyBackground from '../elements/LazyBackground';
import LoadingSpinner from '../elements/LoadingSpinner';

// constants
import blogs from '../constants/blogs';

/**
 * @function Blog
 * @description Functional component containing blog page markup
 * @returns {JSX} Markup for blog component
 */
const Blog = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const imageSrc = LazyBackground('/images/jenny-stretch.jpg');

    return (
        <>
            {imageSrc ?
                <div className='home-page-hero' style={{ backgroundImage: `url(${imageSrc})`, opacity: `${imageSrc ? 1 : 0.5}` }}>
                    <div className='content-wrapper content-wrapper--full'>
                        <h1>Blog Articles</h1>
                        <section>
                            <ul className='blog-list'>
                                {blogs.map((blog, index) =>
                                    <li key={index}><Link href={`/blog/${blog}`}>{capitalize(blog.replaceAll('-', ' '))}</Link></li>
                                )}
                            </ul>
                        </section>
                    </div>
                </div> :
                <div className='full-page'><div className="center-spinner"><LoadingSpinner /></div></div>
            }
        </>
    );
};

export default Blog;
