// libraries
import { useEffect } from 'react';

// utils
import { capitalize } from '../utils/ultility';

// constants
import blogs from '../constants/blogs';

// elements
import Link from '../elements/Link';

// components
import BlogHeader from './BlogHeader';
import BlogSignature from './BlogSignature';

/**
 * @function BlogArticle
 * @description Functional component containing blog article wrapper
 * @returns {JSX} Markup for blog article wrapper
 */
const BlogArticle = ({children}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='blog-article-wrapper'>
            <div className='blog-article-content'>
                <BlogHeader />
                    {children}
                <BlogSignature />
            </div>
            <div className='blog-list-container'>
                <h2>All Blog Articles</h2>
                <section>
                    <ul className='blog-list'>
                        {blogs.map((blog, index) =>
                            <li key={index}><Link href={`/blog/${blog}`}>{capitalize(blog.replaceAll('-', ' '))}</Link></li>
                        )}
                    </ul>
                </section>
            </div>
        </div>
    );
};

export default BlogArticle;
