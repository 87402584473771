// libraries
import { useEffect } from 'react';

// elements
import Link from '../elements/Link';

// modules
import Location from '../modules/Location';
import Etiquette from '../modules/Etiquette';
import Policies from '../modules/Policies';

/**
 * @function Studio
 * @description Functional component containing studio page markup
 * @returns {JSX} Markup for studio page component
 */
const Studio = ({ component }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <h1>Studio</h1>
            <section>
                <nav>
                    <ul>
                        <li><Link href="/studio/location" className={component === 'location' ? 'active' : ''}>Location</Link></li>
                        <li><Link href="/studio/etiquette" className={component === 'etiquette' ? 'active' : ''}>Etiquette</Link></li>
                        <li><Link href="/studio/policies" className={component === 'policies' ? 'active' : ''}>Policies</Link></li>
                    </ul>
                </nav>
                {component === 'location' && <Location /> }
                {component === 'etiquette' && <Etiquette /> }
                {component === 'policies' && <Policies /> }
            </section>
        </>
    );
};

export default Studio;