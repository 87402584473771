// component pages
import Home from '../pages/Home';
import Blog from '../pages/Blog';
import Studio from '../pages/Studio';
import About from '../pages/About';
import ClassList from '../pages/ClassList';
import Calendar from '../pages/Calendar';

// constants
import blogPaths from './blogRoutes';

/**
 * @constant sitePaths
 * @description Object list of corresponding component and path name as string
 * @returns {Object} Object group of paths for site
 */
const sitePaths = [
    {
      component: <Home/>,
      path: '/'
    },
    {
      component: <About />,
      path: '/about'
    },
    {
      component: <Studio component="location" />,
      path: '/studio'
    },
    {
      component: <Studio component="location" />,
      path: '/studio/location'
    },
    {
      component: <Studio component="etiquette" />,
      path: '/studio/etiquette'
    },
    {
      component: <Studio component="policies" />,
      path: '/studio/policies'
    },
    {
      component: <Blog />,
      path: '/blog'
    },
    {
      component: <ClassList />,
      path: '/classes'
    },
    {
      component: <Calendar />,
      path: '/calendar'
    },
    ...blogPaths
];

export default sitePaths;
