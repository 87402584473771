// libraries
import { useEffect } from 'react';
import Link from '../elements/Link';

/**
 * @function Etiquette
 * @description Functional component containing etiquette component markup
 * @returns {JSX} Markup for etiquette component
 */
const Etiquette = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return (
        <>
            <p>Following our tips below can help ensure the best experiences for everyone.</p>
            <ol>
                <li><p>Arrive on time. Arriving late is disruptive to the class and the instructor. For Hot Yoga please arrive no more than 20 minutes early to ensure a space in the studio and to acclimate your body to the heat.</p></li>
                <li><p>Don't eat directly before practicing yoga. Standard recommendations are to fast for at least 2 hours before. If you are still digesting or practicing with a full belly, this could cause you to feel sick, especially during heated classes, nullifying the benefits of the class.</p></li>
                <li><p>Wear the appropriate clothing for exercise. Your clothing should be comfortable for you. Also, please remove your footwear before entering the studio during yoga classes. This is a sacred space. Shoes are allowed during Zumba classes, please ensure proper footwear.</p></li>
                <li><p>Everyone should be mindful of their own personal hygiene. Avoid strong scents that can irritate and bother other students.</p></li>
                <li><p>Please silence your phones and refrain from using any other technical items during class. This is distracting to the instructor as well as other students. Take the class time to tune into yourself and disconnect from outside distractions.</p></li>
                <li><p>You can leave any larger items in the provided cubby. You are welcome to keep smaller objects with you, towel, water, etc.</p></li>
                <li><p>Please refrain from conversations during the actual class time.</p></li>
                <li><p>If you need to leave before the class ends, please do so quietly and before Savasana (final resting yoga pose) to limit disruptions to others.</p></li>
                <li><p>If at any time you are struggling to keep up with the class or having difficulty breathing during Yoga or Zumba, come out of the pose (yoga), stop dancing (Zumba), and focus on your breathing while either sitting, laying down, or utilizing child's pose. Pace yourself and remember that yoga is not about positioning yourself perfectly in every posture, it is about connecting your mind, body, and breath.</p></li>
                <li><p>If there is any chance of inclement weather, please contact us or <Link href="/calendar">check our schedule</Link> for closing or cancellations.</p></li>
                <li><p>Have fun! Positivity and consistency are key!</p></li>
            </ol>
            <p>And please always remember to respect yourself, your instructor, and your peers.</p>
        </>
    );
};

export default Etiquette;