// libraries
import { useEffect } from 'react';

// components
import Socials from '../components/Socials';

// elements
import LazyImage from '../elements/LazyImage';

/**
 * @function About
 * @description Functional component containing about page markup
 * @returns {JSX} Markup for about page component
 */
const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <h1>About Us</h1>
            <div className="wrapper">
                <LazyImage
                    imgSrc='/images/about-jenny.jpg'
                    textName='Jenny Neal-Teekasingh'
                    showLoader={true}
                    className=""
                />
                <section>
                    <img className="logo" src="/images/lotus.svg" alt="love one yogi logo as decoration" />
                    <p>Jenny Neal-Teekasingh is a CYT-200 licensed Yoga instructor as well as a certified Zumba instructor. She is dedicated to wellness and maintaining mobility and activeness in adults. She began taking dance classes at the age of 5 years old, and performed with a dance group and competed in the Philadelphia area for many years. Jenny found yoga when she was 17 years old during her freshman year of college; her love for yoga began that year. She has been cultivating her relationship with yoga and pursuit of wellness ever since. When Jenny is not teaching or practing yoga or Zumba, she is a <a href="https://jennynt.com" target="_blank" rel="noreferrer">website developer</a>, artist, <a href="https://www.instagram.com/jennymadeofstars/" target="_blank" rel="noreferrer">writer</a> and <a href="https://heart.travel" target="_blank" rel="noreferrer">entrepreneur</a>.</p>
                    <Socials />
                </section>
            </div>
        </>
    );
};

export default About;