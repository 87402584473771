/**
 * @function Footer
 * @description Functional component containing footer markup
 * @returns {JSX} Markup for footer component
 */
const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <footer>
            <div className="footer-content">
                <span>&copy; {year} Love One Yogi <img src="/images/lotus.svg" alt="Love One Yogi logo" /></span>
                <span>Designed/Developed by <a href="https://jennynt.com" target="_blank" rel="noreferrer">Jenny Neal-Teekasingh</a></span>
            </div>
        </footer>
    );
};

export default Footer;
