/**
 * @constant blogs
 * @description Array of blogs
 * @returns {Array} String list of array blogs
 */
const blogs = [
    'five-ways-to-get-grounded',
    'seven-reasons-to-start-a-daily-practice',
    'the-raw-food-pyramid',
    'what-is-yoga',
    'yoga-and-grief'
];

export default blogs;