// libraries
import { useEffect } from 'react';

// elements
import Link from '../elements/Link';
import LazyImage from '../elements/LazyImage';

/**
 * @function ClassSchedule
 * @description Functional component containing class schedule markup
 * @returns {JSX} Markup for class schedule component
 */
const ClassSchedule = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <h1>Classes</h1>
            <div className="wrapper">
                <LazyImage
                    imgSrc='/images/classes.jpg'
                    textName='Jenny Neal-Teekasingh teaching a yoga class'
                    showLoader={true}
                    className=""
                />
                <section>
                    <img className="logo" src="/images/lotus.svg" alt="love one yogi logo as decoration" />
                    <p>
                        <strong>Love One Yogi Studio</strong> offers a variety of classes, where all levels are welcome. If you are uncertain where to start or are new to yoga or Zumba, let us know and we will help you find the confidence to get started.
                    </p>
                    <div className='list-item'>
                        <h2>Zumba</h2>
                        <p>Cost $5 / Duration 50 mins</p>
                    </div>
                    <div className='list-item'>
                        <h2>Hot Yoga / Vinyasa Yoga / Chair Yoga</h2>
                        <p>Cost $10 / Duration 1 hour</p>
                    </div>
                    <div className='list-item'>
                        <h2>Guided Meditations</h2>
                        <p>Cost $7 / Duration 30 mins</p>
                    </div>
                    <br />
                    <Link href="/calendar" className="button-primary">View Class Calendar</Link>
                    <br />
                    <Link href="/studio" className="button-primary">View Studio Location</Link>
                    <p className='disclaimer left'>**Pricing listed is per person/per class. Offerings, days and times are subject to updates and changes.</p>
                </section>
            </div>
        </>
    );
};

export default ClassSchedule;
