// libraries
import { useEffect } from 'react';

// elements
import Link from '../elements/Link';

/**
 * @function Policies
 * @description Functional component containing policies markup
 * @returns {JSX} Markup for policies component
 */
const Policies = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return (
        <>
            <p>Are you new to our studio? Please familiarize yourself with our policies before attending a class. Thank you.</p>
            <h2>Current Studio Policies</h2>
            <p>Each spot in the class is available on a first-come, first-serve basis. Please arrive no more than 20 minutes early. Adults only, please.</p>
            <h3>What to bring with you?</h3>
            <p>Please bring your own yoga mat, water, and a towel. We will also have cold water available for purchase ($2 cash only)  if you should need it. If you require yoga props, please bring your own, otherwise, we have a limited number of mats and props available for use.</p>
            <p>No late arrivals are allowed, please respect others by arriving on time to avoid disruptions in the classes. Doors will be open 20 minutes before each class.</p>
            <h3>Parking</h3>
            <p>You can park in our driveway, in front of the garage, along the front of our house, or along Chaffee Street. But please refrain from parking on our lawn. Give yourself ample time to park to ensure you arrive on time for class.</p>
            <h3>Entrance</h3>
            <p>The entrance to our studio is through the side fence to the left of our house. The side entrance leads directly into the studio. If you are taking a yoga class, please remove your shoes as soon as you enter the studio and place them into a spot in the cubby.</p>
            <h3>Check-In</h3>
            <p>Upon checking in, please sign in, pay your class fee, and claim your spot in the class.</p>
            <h3>Personal Items</h3>
            <p>Please keep the personal items you bring with you to a minimum. Please refrain from bringing  large bags or unnecessary items to class. You may store your items in the cubby, but please remember to take them with you. Any items left behind will be discarded at the end of the week.</p>
            <h3>Studio Etiquette</h3>
            <p>For tips on what to expect, please check out our <Link href="/studio/etiquette">etiquette list</Link>.</p>
        </>
    );
};

export default Policies;
