// components
import BlogArticle from '../components/BlogArticle';

/**
 * @function YogaAndGrief
 * @description Functional component containing blog markup
 * @returns {JSX} Markup for blog article
 */
const YogaAndGrief = () => {
    return (
        <BlogArticle>
            <>
                <h1>Yoga And Grief</h1>
                <p className="date">January 17, 2019</p>
                <p>The thing I was least prepared for happened and it completely took the wind out of my sails. I don't think I've ever felt so lost. Feelings of emptiness completely overtook me and it became that same old routine of just trying to get through the day.</p>
                <p>Why was it so difficult now? Getting through the day has never been such a chore for me before. Now I am doing my very best to keep from crying at everything that reminds me of the loss I've just claimed as my own.</p>
                <p>My daily yoga practice was something that brought me a great sense of calm. These feelings of peace, one would think would not be so easily shattered and yet they were. I found myself 11 days out of my daily practice. I felt it in every part of my body. The stiffness finding its way into my body. I was so upset that I honestly don't think there was a part of me that minded. Just thinking about getting out onto my mat made my insides quiver and the threat of tears begin to pulse in my eyes. </p>
                <p>But I had made up my mind. Today was the day. I would get back out onto my mat. I would take this time for myself and allow myself a few moments of reprieve. The grief would be there after my practice.</p>
                <p>My ritual always begins the same. Lighting an incense or a candle. Whichever I happen to have on hand. And this time it was the incense. White Rose in particular. I lit the flame to get it started. I walked over to my mat. Rolled it out. Walked on with the anticipation of the entire world collapsing as soon as I did. But you know what? It didn't. The world didn't end. My thoughts of loss didn't diminish. But as I lay in Balasana, arms behind me, palms facing upward toward the universe, I felt a wave of surrender. The breath I had been denying myself for the past 11 days had finally begun to fill me. I continued through the hour, honoring myself and allowing myself time to just breathe. Breathing in and out with each pose. Grounding poses, affirming my stance in this world. I am here and although things are no longer the way I would like them to be, I will continue to be here. Remaining. Breathing. Making it through, one second, minute, hour, and a day at a time. </p>
                <p>To any of you dealing with grief or loss, hopefully, you can find a few moments of relief. If that is with yoga or some other means. May you find solace.</p>
            </>
        </BlogArticle>
    );
};

export default YogaAndGrief;