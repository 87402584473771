// components
import BlogArticle from '../components/BlogArticle';

/**
 * @function WhatIsYoga
 * @description Functional component containing blog markup
 * @returns {JSX} Markup for blog article
 */
const WhatIsYoga = () => {
    return (
        <BlogArticle>
            <>
                <h1>What is Yoga? My brief introduction.</h1>
                <p className="date">January 23, 2019</p>
                <p>For many people, yoga class is a place they can go to get away from everything. A place for a little "me time." A place where you go to "get bendy." As a recently accredited Yoga teacher, my ideas of Yoga have changed. Before I embarked on my Yoga certification journey, Yoga was a time to do something good for my body, a bit of exercise if you will, a place where the things in my mind suddenly didn't seem quite so loud. It is a magical feeling. And if you practice, you know what I'm talking about. It was part of the day when I granted myself permission to breathe. Like really breathe. If that makes sense. We go through our days breathing and that's what keeps us alive. But do you notice as you go through the day how shallow your breath is? How often do you hold your breath? I noticed it because I was and still am guilty of it.</p>
                <p>My realization of what Yoga is has changed over the past year. Yoga, the term means "to yoke", or in other words "to unite" or even more clearly, "to make one." Yes, it's that simple, and yet putting it into action is not as easy as it sounds. Make yourself one with what? Yourself? Aren't you already one being? Yes, technically that is true. But the purpose of Yoga is actually to make yourself one with the Divine. By quieting the fluctuations of the mind, you are taking in all that there is. Without the focus on yourself and the daily grind, you are allowing yourself the chance to just be and accept your place in the universe and allowing all the goodness that it has to flow through you. Practicing the asanas and "getting bendy" is super great too. Don't get me wrong. The health benefits that the physical practice of Yoga can provide alone are great reasons to practice. Your organs getting stretched and allowing your breath to filter through your body in ways it hadn't before are monumental life changers.</p>
                <p>I am not the authority on what defines a proper yoga practice. But I will be sharing my opinions and tips for anyone looking to get started and anyone already on their journey.</p>
            </>
        </BlogArticle>
    );
};

export default WhatIsYoga;
