// libraries
import { useEffect } from 'react';

// components
import Socials from '../components/Socials';

/**
 * @function Location
 * @description Functional component containing location markup
 * @returns {JSX} Markup for location component
 */
const Location = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return (
        <div className="location-group">
            <section>
                <address>
                    Love One Yogi Studio<br />
                    421 Dryden Harford Road<br />
                    Dryden, NY 13053<br />
                </address>
                <Socials />
            </section>
            <section>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11774.970981361226!2d-76.282667!3d42.4544894!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89da7b27a1d95247%3A0x18241a238c0c5aa4!2s421%20Dryden%20Harford%20Rd%2C%20Dryden%2C%20NY%2013053!5e0!3m2!1sen!2sus!4v1677779614044!5m2!1sen!2sus"
                    width="400"
                    height="400"
                    style={{ border: 0 }}
                    loading="lazy"
                    title="Map of Love One Yogi Studio"
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </section>
        </div>
    );
};

export default Location;
