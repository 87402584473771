/**
 * @function Socials
 * @description Functional component containing socials markup
 * @returns {JSX} Markup for socials links
 */
const Socials = () => {
    return (
        <div className="socials">
            <p>Connect with us:</p>
            <p>
                <a
                    href="https://instagram.com/loveoneyogi"
                    target="_blank" rel="noreferrer"
                    title="Love One Yogi on Instagram">
                    <span className="icon-instagram"></span>
                </a>
                <a
                    href="https://www.facebook.com/jenny.nealteekasingh/"
                    target="_blank" rel="noreferrer"
                    title="Love One Yogi on Facebook">
                    <span className="icon-facebook"></span>
                </a>
                <a
                    className="zumba-logo"
                    href="https://www.zumba.com/en-US/profile/jennifer-neal-teekasingh/2032186"
                    target="_blank" rel="noreferrer"
                    title="Zumba instructor profile">
                    <img src="/images/zumbalogo.png" alt="Zumba logo" />
                </a>
            </p>
            <br />
            <p>Email us at:</p>
            <p>
                <a href="mailto:loveoneyogi@gmail.com">loveoneyogi@gmail.com</a>
            </p>
        </div>
    );
};

export default Socials;
